<template>
  <div class="page">
    <section>
      <span class="title">重新编辑</span>
      <div class="box">
        <el-input
          autocomplete="off"
          v-model="input"
          placeholder="HPV16阳性tct正常严重吗？"
        ></el-input>
        <div class="group">
          <div class="upload">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="iconfont bofang2"></i>
              <div class="re">
                <span class="uploading">重新上传</span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div class="upload">
            <div class="upload">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
              >
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
                <div class="re">
                  <span class="uploading">重新上传</span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisibles">
                <img width="100%" :src="dialogImageUrls" alt="" />
              </el-dialog>
            </div>
          </div>
        </div>
        <el-input
          autocomplete="off"
          type="textarea"
          :rows="2"
          placeholder="可以适当休息休息，平时多吃说过蔬菜和粗纤维的食物可以适当休息休息，平时多吃说过蔬菜和粗纤维的食物可以适当休息休息，平时多吃说过蔬菜和粗纤维的… "
          v-model="textarea"
        >
        </el-input>
        <div class="popUps">
          <div class="bubble">
            <div class="bubbleBox">
              <span class="theReason"
                >审核不通过原因审核不通过原因审核不通过因审核不核不
                原因审核不通过原因审核不通过原因审核不因审核不核不
                通过原因</span
              >
            </div>
          </div>
          <i class="iconfont guanbi"></i>
        </div>
        <img
          @click="$router.push({ path: '/creation' })"
          class="release"
          src="@image/fb.png"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      dialogImageUrl: "",
      dialogVisible: false,
      textarea: "",
      dialogImageUrls: "",
      dialogVisibles: false,
      disabled: false,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      file, fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrls = file.url;
      this.dialogVisibles = true;
    },
    handleDownload(file) {
      file;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .el-input {
    background: none !important;
    .el-input__inner {
      width: 1080px;
      border-bottom: 2px solid $--themeColor;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  .el-upload--picture-card {
    width: 310px;
    height: 150px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #8f8f8f;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-top: 24px;
    .re {
      z-index: 5;
      position: absolute;
      width: 310px;
      height: 30px;
      opacity: 0.4;
      background: #000000;
      border-radius: 0px;
      border-radius: 0 0 10px 10px;
      margin-top: 120px;
      color: #ffff;
      text-align: center;
      line-height: 25px;
      cursor: pointer;
    }
    &:hover {
      border: 1px solid $--themeColor;
      background: #fbfbfb;
      color: $--themeColor;
      i {
        color: $--themeColor;
      }
    }
  }
  .el-textarea {
    width: 1080px;
    border: none;
    > .el-textarea__inner {
      border: none;
      height: 130px;
      padding: 0;
      margin-top: 36px;
      resize: none;
    }
  }
  > .el-upload-list--picture-card .el-upload-list__item {
    width: 310px;
    height: 150px;
    margin-top: 24px;
  }
}

.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      color: #333333;
      font-size: 18px;
      margin-top: 30px;
      font-weight: bold;
    }
    > .box {
      width: 1200px;
      background: #ffff;
      padding: 50px 30px 32px 50px;
      box-sizing: border-box;
      margin-top: 22px;
      > .group {
        display: flex;
        flex-direction: row;
        > .upload {
          margin-right: 60px;
        }
      }
      > .release {
        width: 140px;
        height: 40px;
        margin-left: 980px;
        cursor: pointer;
      }
      > .popUps {
        display: flex;
        flex-direction: column;
        > .bubble {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: url("~@image/bubble.png");
          background-size: 100%;
          width: 401px;
          height: 117px;
          color: #333333;
          font-size: 14px;
          > .bubbleBox {
            width: 388px;
            height: 80px;
            margin-top: 10px;
            margin-left: 5px;
            padding: 15px;
            box-sizing: border-box;
            font-size: 14px;
            color: #333333;
            > .theReason {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-height: 1.5;
            }
          }
        }
        > .iconfont {
          font-size: 12px;
          color: #8f8f8f;
          margin-top: 20px;
          margin-left: 190px;
        }
      }
    }
  }
}
</style>